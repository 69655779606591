* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  body,
  html {
    height: 100%;
    overflow-x: hidden;
  }
  .about_frt_container {
    position: relative;
    width: 100%;
  }
  .about_nav {
    width: 100%;
    height: 110px;
    margin-bottom: 50px;
    backdrop-filter: blur(3px);
  }
  
  .about_top_bg img {
    width: 100%;
    height: auto;
    z-index: -2;
    position: absolute;
    top: 0;
    left: 0;
  }
  .pre_load{
    width: 100%;
    height: 800px;
    z-index: -2;
    position: relative;
    top: 0;
    left: 0;
    background-color: #202e14;

  }
  .about_top_bg::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: rgba(0, 0, 0, 0.5);
  }
  .about_leaf {
    position: absolute;
    top: 0;
    z-index: -1;
  }
  .about_text_ct {
    color: #ffff;
    height: 470px;
    word-wrap: break-word;
    /* border: 2px solid red; */
  }
  .about_frt_text {
    font-family: "Enthalpy 298";
    font-size: 90px;
    line-height:108px;
    font-weight: 400;
  }
  .about,
  .about_highlight {
    display: block;
  }
  .about_highlight{
    color: #ffdd67;
  }
  
  .about_second_text {
    font-family: "Manrope";
    font-size: 27px;
    font-weight: 400;
  }
  .btm_path_about {
    position: relative;
    /* bottom: 4%; */
    left: 0;
    width: 100%;
    height: auto;
  }
   .btm_path_about img {
    width: 100%;
    height: auto;
    margin-top: -6%;
  }
  .about_sec_container{
    position: relative;
    width: 100%;
    height:auto;
    margin-top: -4.5%;
    background-color: #202e14;
  }
  .tree_pic_tank{
    width: 100%;
    height: 70rem;
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;
  }
  .left_stem img{
    margin-top: -160px;
    height: 1220px;

  }
  .right_stem{
    color: white;
    display: flex;
    gap: 2rem;
    margin-top: -10%;

  }
  .right_one img, .right_two img{
    width: 100%;
    height: auto;
  }
  .about_sce_bttm{
    position: absolute;
    bottom: 0;
    left: 0;

  }
  .about_sce_bttm img{
    width: 100%;
    height: auto;
  }
  .mirror_txt{
    display: flex;
    justify-content: center;
    align-items: center;

  }
  
  .second_about_txt{
    position: absolute;
    top: 10%;
    width: 100%;
    height: auto;
    display: flex;
    color: white;
    gap: 10%;
    padding: 0;
}
  .pic_box{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 370px;
    height: 370px;
    margin-bottom: 4rem;

  }
  .pic_box img{
    width: 100%;
    height: auto;
  }
  .text_box h1{
    font-family: "Enthalpy 298";
font-size: 100px;
font-weight: 400;
line-height: 100px;
text-align: left;
width: 510px;

}
.text_box h1, .txt_light{
  color: #ffdd67;
}
.right_text{
  padding-right: 2rem;
}
.para_First{
  margin-bottom: 2rem;
}
.para_First h1, .para_Sec h1{
  font-family: "Enthalpy 298";
  font-size: 50px;
  font-weight: 400;
  line-height: 100px;
  text-align: left;
}
.para_First p, .para_Sec p{
  font-family: "Manrope";
font-size: 30px;
font-weight: 400;
line-height: 45px;
text-align: left;
}
/* THIRD */
.about_third_container{
  position: relative;
  width: 100%;
  height: auto;
  background-color: #202e14;
  margin-top: -2%;
  padding: 0;
  border: none;
  outline: none;

}
.two_top_images{
  position: relative;
  width: 100%;
  height: auto;
}
.third_top_lft {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #202e14;
}
.third_top_rht{
  position: absolute;
  top: 0;
  right: 0;
  background-color: #202e14;
}

.third_top_lft img{
  margin-top: -5%;
}
.third_top_rht img{
  margin-top: -2%;
}
.abt{
  width: 100%;
  position: relative;
}
.text_img_one, .text_img_two{
 width: 100% ;
  height: auto !important;
  color: #FFFF;
  display: flex;
  justify-content: space-between;
}
.text_img_one{
  margin-bottom: 5rem;
  padding-top: 10%;

}
.home_one{
  width: 900px;
  height: auto;

}
.home_two {
  width: 1800px;
  height: auto;

}
.home_one img, .home_two img{
  width: 100%;
  height: auto;
 
}
.img_txt_first h1, .img_txt_second h1{
  font-family: "Enthalpy 298";
font-size: 100px;
font-weight: 400;
line-height: 100px;
text-align: left;
padding: 0 !important;
}
.img_txt_first p, .img_txt_second p{
  font-family: "Manrope";
  font-size: 30px;
  font-weight: 400;
  line-height: 45px;
  text-align: left;
  padding: 0 !important;

}
.btmAbout{
position: relative;
z-index: 1;
width: 100%;
height: auto;
margin: 0;
border: none;
outline: none;

}
.btmAbout img{
  width: 100%;
  border: none !important;

}
/* FOURTH */
.about_fourth_container{
  position: relative;
  width: 100%;
  height: auto;
  outline: none;
  margin-top: -1%;
}
/* Below 768px */
@media (max-width: 767px) {
  .about_nav {
    height: 20px;
  margin-bottom: 0;
  }
  .text-center{
    height: auto;
  }
  .about_text_ct {
  margin-top: 35px;
  }
  .about_frt_text {
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 0;
  }
  .about_second_text {
  font-size: 6px;
  line-height: 12px;
  }
  .btm_path_about img {
  margin-top: -50px;
  }
  .about_sec_container{
  margin-top: -8%;
  }
  .tree_pic_tank{
  height: 440px;
  }
  .left_stem img{
  height: auto;
  }
  .second_about_txt{
  gap: 40px;
  }
  .pic_box{
    width: 150px;
  height: auto;
  margin-bottom: 20px;
  }
  .text_box h1{
  font-size: 30px;
  line-height: 30px;
  width: auto;
  padding-left: 20px;
  }
  .para_First h1{
    padding-top: 30px;
  }
  .para_First{
    margin-bottom: 0;
  }
  .para_First h1, .para_Sec h1{
  font-size: 16px;
  line-height: 20px;
  }
  .para_First p, .para_Sec p{
  font-size: 12px;
  line-height: 20px;
  }
  .right_text{
    padding-top: 0rem;
    padding-right: 0rem;

  }
  /* THIRD */
  .about_third_container{
  margin-top: 0;
  }
  .text_img_one, .text_img_two{
  display: block;
  margin-bottom: 1rem;
  padding-top: 60px;
  }
  .img_txt_first h1, .img_txt_second h1{
  font-size: 50px;
  line-height: 50px;
  }
  .home_one, .home_two{
  width: 100%;
  }
  .img_txt_first p, .img_txt_second p{
  font-size: 15px;
  line-height: 20px;
  }

}
/* For screen widths between 768px and 1024px */
@media (min-width: 768px) and (max-width: 1023px) {
  .pre_load{
    height: 390px;
  }
  .about_nav{
    height: 50px;
    /* height: 6rem;
    margin-bottom: 1rem; */
  }
  .about_text_ct{
    height: 270px;
  }
  .about_frt_text {
    font-size: 50px !important;
    margin-bottom: 0;
  }
  .about_frt_text {
  font-size: 50px;
  line-height: 55px;
  }
  

  .about_second_text {
    font-size: 16px;
    line-height: 25px;
  }
  .btm_path_about img{
    margin-top: -10%;
  }
  .about_sec_container{
    margin-top: -6%;
  }
  .tree_pic_tank{
    height: 900px;
  }
  .pic_box{
    width: 300px;
    margin-bottom: 0;
  }
  .second_about_txt{
    top: 0;
    gap: 2%;
    padding-left: 0;
  }
  .right_text{
    padding-top: 25%;
    padding-right: 0;
  }
  .text_box h1{
    font-size: 50px;
    line-height: 60px;
    width: 200px;
  }
  /* THIRD */
  .para_First h1, .para_Sec h1{
    font-size: 45px;
    line-height: 45px;
  }
  .para_First p, .para_Sec p{
    font-size: 25px;
    line-height: 35px;
}

.img_txt_first h1, .img_txt_second h1{
  font-size: 50px!important;
  line-height: 60px;
}
.img_txt_first p, .img_txt_second p{
  font-size: 20px;
  line-height: 35px;
}
.img_txt_first h1, .img_txt_second h1{
  font-size: 50px;
}
  
  
}
/* For screen widths between 1024px and 1440px */
@media (min-width: 1024px) and (max-width: 1350px) {
  .about_nav{
    margin-bottom: 0;
  }
  .about_text_ct{
    height: 450px;
  }
  .about_frt_text {
    font-size: 55px;
    line-height: 55px;
    }
    .pre_load{
      height: 580px;
    }
    .about_second_text {
      font-size: 25px;
      line-height: 30px;
    }
  .btm_path_about img {
    margin-top: -25%;
  }
  .about_sec_container{
    margin-top: -13%;
  }
  .para_First h1, .para_Sec h1{
    line-height: 60px;
  }
  .right_text{
    padding-top: 0;
  }
  
  .right_text{
    padding-right: 0;
  }

}
/* For screen widths 1920px and above */
@media (min-width: 1920px) {
  .pre_load{
    height: 850px;
  }
  .about_text_ct {
    margin-top: 5%;
  }
  .about_frt_text{
    margin-bottom: 4%;
  }

  .btm_path_about img {
    margin-top: 0;
  }
  
  
}
@media screen and (min-width: 2560px) {
  .pre_load{
    height: 1000px;
  }
  .about_third_container{
    margin-top: 0;
  }
  .about_text_ct {
    margin-top: 10%;
  }
  
  .third_top_rht img{
    margin-top: -8%;
  }
  


}