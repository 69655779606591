* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body,
html {
  height: 100%;
  overflow-x: hidden;
}
.gallery_page {
  position: relative;
}
/* MODAL */
.carousel_img {
  width: 100%;
  height: auto;
  overflow: hidden;
}
.carousel_img img {
  width: 100%;
  height: 80vh;
}
.carousel_img video{
  width: 100%;
  height: 80vh;
}
.car_pannellum{
  width: 100vh;
  height: 80vh;
  object-fit: cover;
}

.modal-blur {
  filter: blur(15px);
  pointer-events: none;
}
.modal-footer{
  position: absolute;
  top: 2%;
  right: 2%;
  width: 35px;
  height: 35px;
  border-radius: 55px;
  border-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:#3c502b;
  z-index: 10;
}
.modal-footer img{
  width: 17px;
  height: 17px;
  cursor: pointer;
}
.img_outer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.right_arrow{
  position: absolute;
  width: 15px;
  height: 20px;
  top: 50%;
  right: 3%;
  cursor: pointer;
}
.left_arrow{
  position: absolute;
  top: 50%;
  left: 3%;
  cursor: pointer;
  width: 15px;
  height: 20px;
}
.pnlm-loading {
  display: none !important; 
}
.pnlm-load-box {
  z-index:-999;
  opacity: 0;
}
/* FIRST */

.first_container {
  position: relative;
  width: 100%;
  height: auto;
  background-color: #3c502b;
  z-index: 0;

}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); 
  z-index: 1; 
}


.top_nav_gallery {
  position: relative;
  width: 100%;
  height: 100px;
  margin-bottom: 3%;
  backdrop-filter: blur(2px);
  z-index: 1;
}

.top_bg img {
  width: 100%;
  height: auto;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

.top_leaf {
  position: absolute;
  top: 0;
  z-index: -1;
}
.top_leaf img{
  width: 100%;
  height: 100%;
}
.top_text_1 {
  color: #ffff;
  overflow-wrap: break-word;
  margin-top: -2%;
 
}
.first_text {
  position: relative;
  font-family: "Enthalpy 298";
  font-size: 100px;
  line-height: 120px;
  font-weight: 400;
  z-index: 2;
  margin-bottom: 1%;
}
.explore,
.highlight {
  display: block;
}
.highlight,
.section.active {
  color: #ffdd67;
}

.sec_text {
  position: relative;
  z-index: 2;
  font-family: "Manrope";
  font-size: 30px;
  font-weight: 400;
  line-height: 39px;
  padding: 0;
}
.top_path {
  position: relative;
  bottom: 55px;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 1;
}

.top_path img {
  width: 100%;
  height: 100%;

}

/* SECOND */
.sec_container {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: -6.8%;
  padding: 0;
  overflow: hidden;
  background-color: rgb(32, 46, 20);
}

.grid_view {
  width: 100%;
  background-color: rgb(32, 46, 20);
  padding: 0% 11% 11%;
  position: relative;
}
.grid_photo {
  width: 100%;
  height: auto;
  background-color: rgb(32, 46, 20);
}
.grid_top {
  font-family: "Enthalpy 298";
  font-size: 40px;
  font-weight: 400;
  line-height: 100px;
  /* text-align: left; */
  color: rgba(255, 255, 255, 1);
  cursor: pointer;

}
.text_grid {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.active_sec {
  position: relative;
  display: block;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0;
  margin: 0;
  height: 120px;
}
.active_sec img {
  width: 47px;
  height: 20px;
  margin-top: -100px;
}
.line_2 {
  margin-bottom: 6%;
}
.vertical-scrollable {
  height: 920px;
  overflow-y: auto; 
  direction: rtl;
   z-index: 2;
  margin: 0;
  width: 100%;
  padding-left: 15px;
  scrollbar-gutter: stable both-edges;
}
.vertical-scrollable > .container {
  direction: ltr; 
}
.vertical-scrollable::-webkit-scrollbar {
  width: 3px;
}
.vertical-scrollable::-webkit-scrollbar-track {
  /* background-color: #2d411c; */
  border-radius: 10px;
}
.vertical-scrollable::-webkit-scrollbar-thumb {
    border-radius: 10px;
    /* width: 4px; */
    background-color: transparent; 
    /* transition: background-color 0.3s ease-in-out; */
  }
  .vertical-scrollable.scrolling::-webkit-scrollbar-thumb {
    background-color: rgba(255, 221, 103, 1); 
  }
  .emptyMedia{
  font-family: "Manrope";
  font-size: 25px;
  font-weight: 400;
  line-height: 39px;
  color: rgba(255, 255, 255, 1);
  }
  .row_images {
    display: flex;
    flex-wrap: wrap;
    gap: 0; 
   row-gap: 60px; 
  }
.row_images img {
  position: relative;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  border-radius: 15px;
  width: 100%;
  height: 250px;
  object-fit: cover;
  z-index: 2;
}
.play_btn{
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 cursor: pointer;
  z-index: 2;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: rgba(36, 62, 28, 0.8);
}
.play_btn img{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  width: 20px;
  height: 40px;
  object-fit: unset !important;
}
.right_tree {
  position: absolute;
  top: 1rem;
  right: 0;
  margin: 0;
  padding: 0;
}
.right_tree img {
  width: 100%;
  height: auto;
}
.secBottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;
}

.secBottom img {
  width: 100%;
  height: auto;
}
/* THIRD */
.third_container {
  background-color: rgb(32, 46, 20);
  position: relative;
  top: 0  ;
  width: 100%;
  height: 100%;
}
.third_top_leaf {
  position: relative;
  top: 0;
  left: 0;
  margin-top: -60px;
}
.third_top_leaf img {
  width: 100%;
  height: auto;
}
.third_text {
  position: relative;
  text-align: center;
  color: rgba(255, 255, 255, 1);

}
.text_1 {
  font-family: "Enthalpy 298";
  font-size: 100px ;
  font-weight: 400 ;
  line-height: 100px ;
  margin-bottom: 4% ;
  }
.text_2  {
  font-family: "Manrope";
  font-size: 30px;
  font-weight: 400;
  line-height: 45px;
}
.third_btm_pic{
  position: relative;
  top: 0;
  bottom: 0;
  width: 100%;
  min-height: 100%;
  /* padding-bottom: 1px */

}
.third_btm_pic img{
  width: 100%;
  min-height: 100%;
    /* margin: 0 !important; */
  /* border: none !important;  
  outline: none !important; */
}

.fourth_container {
  position: relative;
  width: 100%;
  height: fit-content;
  top: 0;
  left: 0;
  /* margin-top: 0 !important; */
 
  }
  /* Below 768px */
@media (max-width: 767px) {
  .top_nav_gallery {
  height: auto;
  margin: 0 !important;
  padding: 0 !important;
  }
  .first_text {
    font-size: 20px;
    line-height: 20px;
    /* margin-top: 18%; */
    margin-top: -20px;
  }
  .sec_text {
  font-size: 10px;
  line-height: 10px;
  }
  .top_path {
  bottom: 10px;
  }
  /* SECOND */  
.vertical-scrollable{
  height: 440px;
}
.right_tree img{
  height: 700px;
}
.row_images {
row-gap: 30px;
}
.row_images img {
height: 200px;
}
.grid_top {
  flex-wrap: nowrap;
  font-size: 15px;
  line-height: 10px;
  margin: 0;
  padding-top: 15px;
}

.section {
  width: auto; 
}
.line_2{
  margin-top: -20px;
}
.emptyMedia{
font-size: 10px;
line-height: 10px;
}

.active_sec {
height: 40px;
}
.active_sec img {
  width: 25px;
  height: 10px;
  margin-top: 10px;
}
/* THIRD */
.text_1{
  font-size: 30px;
  line-height: 25px;
}
.text_2{
  font-size: 18px;
  line-height: 30px;
}
.third_top_leaf {
  margin-top: -5%;
}

/* MODAL */
.carousel_img img {
  height: 30vh;
  width: 38vh;

}
.carousel_img video{
  height: 30vh;
  width: 38vh;

}
}
  /* For screen widths between 768px and 1024px */
@media (min-width: 768px) and (max-width: 1023px) {
  .top_text_1 {
    margin-top: -4%;
  }
  .first_text {
    font-size: 40px;
    line-height: 40px;
  }
  .sec_text {
    font-size: 18px;
    line-height: 25px;
}
.top_path {
bottom: 25px;
}
.third_top_leaf {
  margin-top: -30px;
}
/* SECOND */
.row_images{
  row-gap: 20px;
}
.vertical-scrollable {
  height: 550px;
}
.grid_top {
  font-size: 20px;
  line-height: 80px;
  font-weight: 100;
  letter-spacing: 1px;

}
.active_sec {
  height: 80px;
}
.emptyMedia{
  font-size: 20px;
}
/* .active_sec img {
  margin-top: 20px;
} */
.sec_container {
  height: 780px;
}
.right_tree img{
  height: 780px;
}
/* THIRD */
.text_1 {
  font-size: 50px;
  line-height: 40px;
}
.text_2  {
  font-size: 20px;
  line-height: 30px;
}
/* MODAL */
.car_pannellum{
  height: 40vh;
  width: 50vh;

}
.row_images img, .row_images video {
  height: 150px;
}
.carousel_img img {
  height: 40vh;
}
.carousel_img video{
  height: 30vh;
}

}
/* For screen widths between 1024px and 1440px */
@media (min-width: 1024px) and (max-width: 1350px) {
  
    .top_text_1 {
      margin-top: -3%;
    }
    .first_text {
      font-size: 45px;
      line-height: 50px;
    }
    .sec_text {
      font-size: 22px;
      line-height: 30px;
  }
  .top_path {
  bottom: 0px;
  }
  /* SECOND */
  .row_images{
    row-gap: 30px;
  }
  .vertical-scrollable {
    height: 550px;
  }
  .sec_container {
    margin-top: -4%;
    height: 900px;
  }
  .right_tree img{
    height: 840px;
  }
  .grid_top {
    font-size: 30px;
  }
  .emptyMedia{
    font-size: 25px;
  }
/* THIRD */
.third_top_leaf {
  margin-top: -50px;
}
.text_1 {
  font-size: 60px;
  line-height: 40px;
}
.text_2  {
  font-size: 25px;
  line-height: 40px;
}

/* MODAL */
.car_pannellum{
  height: 45vh;
  width: 60vh;

}
.row_images img, .row_images video {
  height: 160px;
}
.carousel_img img {
  height: 45vh;
}
.carousel_img video{
  height: 35vh;
}
}
@media (min-width:1440px){
  .top_nav_gallery {
  height: 120px;
  }
}
/* For screen widths 1920px and above */
@media (min-width: 1920px) {
  .top_path{
    bottom: 0px;
  }
  .top_text_1{
    margin-top: 4%;
  }
  .third_top_leaf {
    margin-top: -90px;
  }
  .sec_container {
    height: 1500px;
  }
  /* .third_container {
  padding-bottom: 1px;
  } */
  .fourth_container {
   margin-top: 0;
}
}
@media screen and (min-width: 2560px) {
  .top_nav_gallery{
    margin-bottom: 10%;
  }
  .third_top_leaf {
    margin-top: -120px;
  }
  .sec_container {
    height: 1550px;
  }
  .fourth_container {
    margin-top: -2px;
 }
  
}

