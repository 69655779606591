* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body,
html {
  height: 100%;
  overflow-x: hidden;
}
.footer_container {
  position: relative;
  top: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  overflow: hidden;
}
.footer_top_bg img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.footer_top_bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(32, 46, 20);
  /* opacity: 1; */
  /* mix-blend-mode: multiply; */
}
.footer_top_cloud{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}
.footer_top_cloud img {
  width: 100%;
  height: 100%;
  
}
a{
  text-decoration: none;
  color: rgba(255, 255, 255, 1) !important;
}
.footer_top_text {
  position: relative;
  margin-top: 10%;
  z-index: 3;
  margin-bottom: 10%;
  color: #ffff;
  height: auto;
  left: 0;
  right: 0;
  word-wrap: break-word;
}
.footer_first_text {
  font-family: "Enthalpy 298";
  font-size: clamp(2.5rem, 5vw, 6.25rem);
  line-height: clamp(3rem, 6vw, 7.5rem);
  font-weight: 400;
  margin-bottom: 2rem;
}
.footer_highlight {
  color: #ffdd67;
}
.footer_sec_text,
.footer_third_text {
  font-family: "Manrope";
  font-size: clamp(1.25rem, 2vw, 1.875rem);
  font-weight: 400;
  line-height: 39px;
}
.footer_sec_text {
  margin-bottom: 0.5rem;
}
.footer_third_text {
  margin-bottom: 3rem;
}
.contact {
  font-family: "Sanchez", serif;
  font-size: 25px;
  font-weight: 400;
  line-height: 25px;
}

 .contact .footer_contact  {
  padding: 15px 40px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 96px;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
}

.footer_contact {
  background-color: rgba(0, 81, 32, 0.15);
}

.footer_schedule {
  background-color: rgba(23, 40, 10, 1);
  border: none;
}
.footer_mountain {
  position: relative;
  margin-top: -30%;
  left: 0;
  z-index: 1;
}
.footer_mountain img {
  width: 100%;
  height: 100%;
}
.footer_path {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 20%;
  /* margin-top: -40%; */
  /* top: calc(100% - 34rem); */
  left: 0;
  width: 100%;
  z-index: 2;
}
.footer_path img {
  width: 100%;
  height: 100%;
}
.footer_quick_links {
  position: relative;
  width: 100%;
  height: auto;
  background-color: #294016;
  z-index: 2;
  margin-top:-60px;
  color: rgba(255, 255, 255, 1) !important;
  overflow: hidden;
}
.footer_link {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

}
.footBtmLogo {
  width: 7rem;
  height: 100%;
  cursor: pointer;
}
.footBtmLogo img {
  width: 100%;
  height: 100%;
  margin-top: -2px;
  /* height: auto; */
}
.footer_nested_row {
  margin-bottom: 45px;
}
.footer_social_link {
  display: flex;
  gap: 10px;
}
.footer_social_link_1,
.footer_social_link_2,
.footer_social_link_3,
.footer_social_link_4,
.footer_social_link_5 {
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  gap: 10px;

}
.footer_social_link_1 img {
  width: 9px;
  height: 18px;
}
.footer_social_link_2 img {
  width: 20px;
  height: 20px;
}
.footer_social_link_3 img {
  width: 15px;
  height: 15px;
}
.footer_social_link_4 img {
  width: 18px;
  height: 18px;
}
.footer_social_link_5 img {
  width: 23px;
  height: 16px;
}
.quick_links_list{
  padding-inline: 20px;
}
.quick_link,
.link_type {
  font-family: "Manrope";
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.96px;
  list-style: none; 
}
.quick_link {
  /* font-weight: 700; */
  margin-bottom: 30px;
}
.link_type {
  font-weight: 400;
  margin-bottom: 10px;
}
.email_app {
  font-family: "Manrope";
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: start;
}
.email_app img {
  width: 24px;
  height: 100%;
  margin-right: 4px;
}
.email_details,
.app_details {
  font-weight: 700;
  letter-spacing: 0.96px;
  text-align: start;
}
.footer_copy {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #294016;
}
/* Below 768px */
@media (max-width: 767px) {
  .footer_top_text {
  margin-top: 15%;
  }
  .footer_first_text {
    font-size: 28px;
    margin-bottom: 0;
  }
  .footer_sec_text {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 30px;
  }
  .footer_third_text {
    margin-bottom: 2rem;
    font-size: 16px;
    line-height: 30px;
  }

  .footer_path {
    top: 50%;
  }
  .footBtmLogo {
  width: 90px;
  }
  .footer_quick_links {
  margin-top: -10px;
  }
  .footer_link {
    display: block; 
    width: 50%;
    margin: 0 auto; 
    text-align: center; 
  }
  
  .footer_nested_row {
  margin-bottom: 20px;
  }
  .email_app, .app_details{
    text-align: center;
  }
 
  .quick_link{
    margin-bottom: 5px;
  }
  .link_column_1, .link_column_2, .link_column_3{
    margin-bottom: 40px;
  }
  .footBtmLogo, .footer_social_link_1, .footer_social_link_2, .footer_social_link_5{
    margin: 0 auto; 
  }
  .footer_social_link{
    gap: 0;
  }
}
/* For screen widths between 768px and 1024px */
@media (min-width: 768px) and (max-width: 1023px) {
  
  .footer_top_text {
    margin-top: 10%;
  }
  .footer_contact,
.footer_schedule {
  width: 35%;
} 
.footer_quick_links {
  margin-top: -1rem;
}
}
/* For screen widths between 1024px and 1440px */
@media (min-width: 1024px) and (max-width: 1350px) {  

  .footer_quick_links {
    margin-top: -1%;
  }
}


/* For screen widths 1920px and above */
@media (min-width: 1920px) {
}
